import React from "react";

function FooterForm() {
  let data = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const setData = (key, value) => {
    data[key] = value;
  };

  const isValid = () => {
    let flag = true;
    let error = [];
    if (data.name.length === 0) {
      error.push("Please enter name");
      flag = false;
    }
    if (data.phone.length === 0) {
      error.push("Please enter phone");
      flag = false;
    }
    if (data.email.length === 0) {
      error.push("Please enter email");
      flag = false;
    }
    if (data.message.length === 0) {
      error.push("Please enter message");
      flag = false;
    }
    if (!flag) alert(error.join(", "));
    return flag;
  };

  const handelSubmit = () => {
    if (!isValid()) return;

    let { name, phone, email, message } = data;
    let wpMessage = encodeURI(
      `My self ${name}, my phone number ${phone} and email is ${email}. My purpose is following: ${message}`
    );
    let url = `https://wa.me/+919409767676?text=${wpMessage}`;
    window.open(url);
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <h5>CONTACT US</h5>
        <p>Let us know how we can help you? </p>
        <div className="vspacer4" />
        <input
          onChange={(e) => setData("name", e.target.value)}
          maxLength={42}
          placeholder={"Name"}
        />
        <div className="vspacer4" />
        <div className="vspacer1" />
        <input
          onChange={(e) => setData("phone", e.target.value)}
          maxLength={13}
          placeholder={"Phone"}
        />
        <div className="vspacer4" />
        <div className="vspacer1" />
        <input
          onChange={(e) => setData("email", e.target.value)}
          maxLength={50}
          placeholder={"Email"}
        />
        <div className="vspacer4" />
        <div className="vspacer1" />
        <input
          onChange={(e) => setData("message", e.target.value)}
          maxLength={150}
          placeholder={"Message"}
        />
        <div className="vspacer4" />
        <div className="vspacer2" />
        <div className="fixWithGrid col2">
          <div className="text"></div>
          <div className="align-right">
            <button onClick={handelSubmit}>
              SEND MESSAGE
              <SvgRightArrow />
            </button>
          </div>
        </div>
        <div className="vspacer2" />
      </form>
    </>
  );
}

function SvgRightArrow() {
  return (
    <svg
      className="right-arrow"
      width="20px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 330 330"
      xmlSpace="preserve"
    >
      <path
        d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
    c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
    C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
    C255,161.018,253.42,157.202,250.606,154.389z"
      />
    </svg>
  );
}

export default FooterForm;
