import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./AppLayout";
import AOS from "aos";
import "aos/dist/aos.css";

const Specifications = lazy(() => import("./Products/Specifications"));
const Category = lazy(() => import("./Products/Category"));
const Products = lazy(() => import("./Products"));
const Home = lazy(() => import("./Homepage"));
const Downloads = lazy(() => import("./Downloads"));
const KnowUs = lazy(() => import("./KnowUs"));
const Support = lazy(() => import("./Support"));
const Press = lazy(() => import("./Press"));
const ProcessData = lazy(() => import("./ProcessData"));
const Oem = lazy(() => import("./Oem"));

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-sine",
      delay: 100,
      offset: 100,
    });
    AOS.refresh(); // Refresh AOS on initial load
  }, []);

  return (
    <BrowserRouter>
      <AppLayout>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/convert" element={<ProcessData />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/about" element={<KnowUs />} />
            <Route path="/support" element={<Support />} />
            <Route path="/press" element={<Press />} />
            <Route exact path="/oem" element={<Oem />} />
            <Route path="/products/:category/:modalNo" element={<Specifications />} />
            <Route path="/products/:category" element={<Category />} />
            <Route path="/products" element={<Products />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Suspense>
      </AppLayout>
    </BrowserRouter>
  );
}

function Loading() {
  return <div>Loading...</div>; // Show a loading message or spinner
}

export default App;
