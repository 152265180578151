import { createContext, useState } from "react";
import Loading from "./Loading";
import Header from "./Header";
import Footer from "./Footer";

export const DataContext = createContext();

function AppLayout({ children = <></> }) {
  const [isVariant, setVariant] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);

  return (
    <DataContext.Provider value={{ setVariant, setActiveIndex, setLoading }}>
      <Header activeIndex={activeIndex} isVariant={isVariant} />
      {isLoading && <Loading />}
      <MiddleData data={children} />
      <Footer />
    </DataContext.Provider>
  );
}

// function SvgLogo({ top = 0, side = "right" }) {
//   let style = {
//     top: top + "px",
//   };
//   if (side == "right") style.right = "-410px";
//   else style.left = "-440px";
//   return (
//     <div style={style} className="svg-bg-logos">
//       <svg
//         alt={"n labs logo"}
//         xmlns="http://www.w3.org/2000/svg"
//         xmlnsXlink="http://www.w3.org/1999/xlink"
//         version="1.1"
//         width={"100%"}
//         viewBox="0 0 1024 1024"
//       >
//         <g transform="matrix(1.88 1.08 -1.08 1.87 526.16 452.41)">
//           <g transform="matrix(0.35 0 0 -0.35 -30.61 -26.26)">
//             <path
//               style={{
//                 stroke: "black",
//                 strokeWidth: 3,
//                 strokeDasharray: "none",
//                 strokeLinecap: "butt",
//                 strokeDashoffset: 0,
//                 strokeLinejoin: "miter",
//                 strokeMiterlimit: 10,
//                 fillRule: "nonzero",
//                 opacity: 0.1,
//                 fill: "transparent",
//               }}
//               paintOrder="stroke"
//               transform=" translate(-726.04, -1374.72)"
//               d="m 903.633 1228.46 l 56.172 416.04 l -24.602 -44.46 l -122.058 70.47 l -320.872 -185.26 v -370.52 l 62.012 -35.79 l 73.449 465.49 l 156.668 -23.05 l 119.231 -292.92"
//               strokeLinecap="round"
//             />
//           </g>
//           <g transform="matrix(0.35 0 0 -0.35 30.19 26.2)">
//             <path
//               className="logo_fill"
//               style={{
//                 stroke: "black",
//                 strokeWidth: 3,
//                 strokeDasharray: "none",
//                 strokeLinecap: "butt",
//                 strokeDashoffset: 0, 
//                 strokeLinejoin: "miter",
//                 strokeMiterlimit: 10,
//                 fillRule: "nonzero",
//                 opacity: 0.1,
//                 fill: "transparent",
//               }}
//               paintOrder="stroke"
//               transform=" translate(-899.06, -1225.44)"
//               d="m 1071.45 1521.38 l -72.231 -457.81 l -147.996 15.37 c 0 0 -126.379 296.55 -129.219 308.15 l -57.902 -430.305 l 24.753 44.455 l -0.046 0.04 l 124.336 -71.788 l 320.875 185.238 v 370.52 l -62.57 36.13"
//               strokeLinecap="round"
//             />
//           </g>
//         </g>
//       </svg>
//     </div>
//   );
// }

function MiddleData({ data }) {
  return data;
}

export default AppLayout;
